import { useState } from "react"
import styled from "styled-components"

import { CircularProgress } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"

import { HOMEPROFILES_LIMIT_LIST } from "src/data/homeProfiles/logic/homeProfileConstants"
import { profileNameCopy } from "src/data/homeProfiles/logic/homeProfiles"
import {
  useFetchProfiles,
  usePostProfile,
} from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { TProfileState } from "src/data/homeProfiles/types/homeProfilesTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchOrganizations } from "src/data/organizations/queries/organizationQueries"
import { IOrganization } from "src/data/organizations/types/organizationTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function AddSettingsProfile() {
  const { t } = useTranslate()
  const { navigate } = useRouter()

  const { org } = useOrganization()
  const [templateId, setTemplateId] = useState<string | undefined>(undefined)
  const [name, setName] = useState("")
  const fetchOrganizations = useFetchOrganizations()
  const postProfile = usePostProfile()

  const fetchProfiles = useFetchProfiles({
    orgId: org?.id,
    filter: { limit: HOMEPROFILES_LIMIT_LIST, offset: 0 },
    options: {
      staleTime: 60 * 1000,
      onSuccess: (data) => {
        setTemplateId(data.profiles[0].id)
      },
    },
  })
  const profiles: TProfileState = fetchProfiles.data?.profiles || []
  const profilesCount = fetchProfiles.data?.paging.total_count || 0

  function handleSave(organization: IOrganization | null) {
    if (!organization) {
      throw Error("Active team is undefined")
    }
    const body = {
      copy_from_profile_id: templateId,
      organization_id: organization.id,
      name,
    }
    postProfile.mutate(body, {
      onSuccess: (profile) => {
        navigate(Routes.MonitoringSettingEdit.location(profile.id).pathname)
      },
    })
  }

  function handleChangeTemplate(
    template: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) {
    const id = template.target.value as string
    setTemplateId(id)

    const profileTemplate = profiles.find((p) => p.id === id)
    setName(profileNameCopy(profileTemplate?.name))
  }

  function templateSelectOptions() {
    return profiles.map((p) => (
      <option key={p.id} value={p.id}>
        {p.name}
      </option>
    ))
  }

  if (fetchProfiles.isInitialLoading) {
    return (
      <ContentBox>
        <div>
          <Title>{t(langKeys.profiles_create_header)}</Title>
          <Description>{t(langKeys.profiles_create_description)}</Description>
        </div>
        <div style={{ margin: "0 auto" }}>
          <CircularProgress size={50} />
        </div>
      </ContentBox>
    )
  }

  if (fetchOrganizations.isError) {
    return (
      <ContentBox>
        <Title>{t(langKeys.profiles_create_header)}</Title>
        <Description>{t(langKeys.profiles_create_description)}</Description>
        <MBanner fullWidth type="error">
          {t(langKeys.failed_something_went_wrong)}
        </MBanner>
      </ContentBox>
    )
  }

  return (
    <ContentBox>
      <div>
        <Title>{t(langKeys.profiles_create_header)}</Title>
        <Description>{t(langKeys.profiles_create_description)}</Description>
      </div>

      {profilesCount > 1 && (
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="profile-template">
            {t(langKeys.profiles_create_based_on_title)}
          </InputLabel>
          <Select
            native
            label={t(langKeys.profiles_create_based_on_title)}
            value={templateId}
            onChange={handleChangeTemplate}
            inputProps={{
              name: "settings-profile-template",
              id: "settings-profile-template",
            }}
          >
            {templateSelectOptions()}
          </Select>
        </FormControl>
      )}

      <TextField
        label={t(langKeys.name)}
        required
        value={name}
        fullWidth
        onChange={(e) => setName(e.target.value)}
      />

      {postProfile.isError && (
        <MBanner type="error">
          {t(langKeys.failed_something_went_wrong)}
        </MBanner>
      )}

      <ActionBox>
        <InternalLink to={Routes.MonitoringSettings.location().pathname}>
          {t(langKeys.cancel)}
        </InternalLink>

        <MButtonLegacy
          onClick={() => {
            handleSave(org)
          }}
          loading={postProfile.isLoading}
          disabled={!name}
        >
          {t(langKeys.save)}
        </MButtonLegacy>
      </ActionBox>
    </ContentBox>
  )
}

const Title = styled(MText).attrs(() => ({
  variant: "heading1",
  marginBottom: spacing.XS,
}))`
  margin-bottom: ${spacing.XS};
`

const ContentBox = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  max-width: 48ch;
  width: 100%;
  margin: ${spacing.XL} auto;
`

const ActionBox = styled.div`
  display: flex;
  place-content: center space-between;
`

const Description = styled(MText).attrs(() => ({
  variant: "body",
  marginBottom: spacing.L,
}))``
